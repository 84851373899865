body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@font-face {
    font-family: 'FredokaOne-Regular';
    src: url('/assets/FredokaOne-Regular.ttf');
}

@font-face {
    font-family: 'TitilliumWeb-Regular';
    src: url('/assets/TitilliumWeb-Regular.ttf');
}

@font-face {
    font-family: 'TitilliumWeb-Bold';
    src: url('/assets/TitilliumWeb-Bold.ttf');
}

.qr svg {
    width: 100%;
    height: 100%;
}